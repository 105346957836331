export const publicRoutes = [
  '/login',
  '/logout',
  '/account/reset',
  '/account/forgot',
  '/account/confirm',
];

export const passwordPolicyInfo =
  'Passwords must be at least 8 characters and should include at least 1 number and 1 uppercase letter.';

export const cognitoUserPoolId = 'eu-west-2_b8tpPf9hy';
export const cognitoAppClientId = '7dmetgsj8fb206oibsnrt4sn7v';

export const initialHexes = [
  {
    name: 'Teaching and Learning',
    order: 1,
    statement1: 'T1 - Subject Knowledge',
    statement2: 'T2 - Reading and Literacy',
    statement3: 'T3 - Backwards Planning',
    statement4: 'T4 - Quality of students work',
    statement5: 'T5 - Delivery',
    statement6: 'T6 - Formative Assessment',
  },
  {
    name: 'Leadership',
    order: 2,
    statement1: 'L1 - Vision, values, culture, wellbeing',
    statement2: 'L2 - Leadership depth',
    statement3: 'L3 - Resources, Learning Environment',
    statement4: 'L4 - Strong systems',
    statement5: 'L5 - Outward facing',
    statement6: 'L6 - Marginal gains, innovative, change',
  },
  {
    name: 'Personal Development',
    order: 5,
    statement1: 'P1 - High Quality Careers Provision',
    statement2: 'P2 - Skills for success (inc finance)',
    statement3: 'P3 - British Values',
    statement4: 'P4 - Be Healthy inc RSE',
    statement5: 'P5 - Character, Resilience, Leadership',
    statement6: 'P6 - SMSC',
  },
  {
    name: 'Attitude & Behaviour',
    order: 6,
    statement1: 'A1 - Attendance & Punctuality',
    statement2: 'A2 - Positive relationships',
    statement3: 'A3 - Community involvement (inc charity)',
    statement4: 'A4 - Engagement',
    statement5: 'A5 - High Expectations',
    statement6: 'A6 - Robust systems & Ready to Learn',
  },
  {
    name: 'Curriculum',
    order: 3,
    statement1: 'C1 - Ambitious',
    statement2: 'C2 - Extracurricular / Enrichment',
    statement3: 'C3 - Sequenced',
    statement4: 'C4 - Summative Assessment & Targets',
    statement5: 'C5 - Forward thinking',
    statement6: 'C6 - Cultural capital',
  },
  {
    name: 'Student Support',
    order: 4,
    statement1: 'S1 - Safeguarding',
    statement2: 'S2 - SEND',
    statement3: 'S3 - Academic intervention (inc catch-up)',
    statement4: 'S4 - Student groups PP, HPA, SEN',
    statement5: 'S5 - Student Wellbeing',
    statement6: 'S6 - Early Help & effective partnerships',
  },
];

export const genericSubjects = [
  'Geography',
  'Music',
  'Social Sciences',
  'Science',
  'DT',
  'English',
  'Visual Arts',
  'BEICT',
  'Maths',
  'Drama',
  'MFL',
  'RS',
  'PE',
  'History',
];

export const trustDisabledRoutes = [
  '/summary/[captureId]',
  '/summary/[captureId]/areas',
  '/summary/[captureId]/subjects',
  '/summary/[captureId]/areas/[areaId]',
  '/summary/[captureId]/subjects/[subjectId]',
  '/summary/visualize',
];
