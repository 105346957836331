import { createContext, useContext, useEffect, useMemo } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import useTrustSchools from '../hooks/useTrustSchools';
import { useAuth } from './AuthProvider';
import { hasRole, roles } from '../lib/permissions';
import useSwitchSchool from '../hooks/useSwitchSchool';

const SchoolContext = createContext();

export const useActiveSchool = () => useContext(SchoolContext);

export default function SchoolProvider({ children }) {
  const [activeSchoolId, setActiveSchoolId] = useLocalStorage(
    'activeSchoolId',
    ''
  );
  const { isLoading, isError, data, error } = useTrustSchools();
  const { user } = useAuth();
  const switchSchool = useSwitchSchool();
  const isSuperOrExec = hasRole(user, [
    roles.SUPERADMIN,
    roles.TRUST_EXECUTIVE,
  ]);

  useEffect(() => {
    if (data && !activeSchoolId) {
      setActiveSchoolId(data.self.schoolId || data.schools?.[0]?.id);
    }
  }, [data]);

  useEffect(() => {
    if (isSuperOrExec) switchSchool(activeSchoolId);
  }, [activeSchoolId]);

  const values = useMemo(
    () => ({
      schools: data?.schools,
      activeSchoolId,
      setActiveSchoolId,
    }),
    [data, activeSchoolId]
  );

  return (
    <SchoolContext.Provider value={values}>{children}</SchoolContext.Provider>
  );
}
