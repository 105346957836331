import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';

async function switchSchool(schoolId) {
  const res = await fetch(`/api/schools/${schoolId}/switch`, {
    method: 'PUT',
  });
  return res.json();
}

export default function useSwitchSchool() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useMutation(switchSchool, {
    onError: (error) =>
      enqueueSnackbar('Failed to switch schools, please try again later.', {
        variant: 'error',
      }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries();
      enqueueSnackbar(`Moved to ${data.school.name}`, {
        variant: 'success',
      });
    },
  });
  return mutate;
}
