import Link from 'next/link';
import Image from 'next/image';
import { useState } from 'react';
import { useRouter } from 'next/router';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListAltIcon from '@material-ui/icons/ListAlt';
import HomeIcon from '@material-ui/icons/Home';
import Logo from './Logo';
import { useAuth } from './AuthProvider';
import { publicRoutes } from '../constants';
import { actions, hasPermission } from '../lib/permissions';
import SchoolPicker from './SchoolPicker';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    color: '#000000DE',
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
  },
  navBtn: {
    marginLeft: 30,
  },
  logo: {
    flexGrow: 1,
    display: 'flex',
  },
  logoImage: {
    cursor: 'pointer',
  },
}));

const primaryNavItems = [
  {
    label: 'Home',
    icon: <HomeIcon />,
    href: '/',
  },
  {
    label: 'Manage Users',
    icon: <PeopleIcon />,
    href: '/users',
    requiredAccess: actions.MANAGE_USERS,
  },
  {
    label: 'Data Captures',
    icon: <ListAltIcon />,
    href: '/captures',
    requiredAccess: actions.MANAGE_CAPTURES,
  },
];

const secondaryNavItems = [
  {
    label: 'Settings',
    icon: <SettingsIcon />,
    href: '/settings',
  },
  {
    label: 'Log Out',
    icon: <ExitToAppIcon />,
    href: '/logout',
  },
];

export default function Layout({ children }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const router = useRouter();
  const { user } = useAuth();
  const fullWidthPage = [
    '/areas',
    '/areas/[id]',
    '/hexes',
    '/captures',
    '/summary',
    '/summary/[captureId]',
    '/summary/[captureId]/areas/[areaId]',
    '/summary/[captureId]/subjects/[subjectId]',
  ].includes(router.pathname);

  if (publicRoutes.includes(router.pathname)) return children;

  if (!user) return null;

  const drawer = (
    <div>
      <div style={{ display: 'flex', padding: 16 }}>
        <Logo />
      </div>
      {hasPermission(user, actions.VIEW_TRUST_SCHOOLS) && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 10,
          }}
        >
          <SchoolPicker />
        </div>
      )}
      <Divider />
      <List>
        {primaryNavItems.map(({ icon, label, href, requiredAccess }) => {
          if (requiredAccess && !hasPermission(user, requiredAccess))
            return null;
          return (
            <Link href={href} key={label} passHref>
              <ListItem button>
                <ListItemIcon style={{ padding: '0 8px' }}>{icon}</ListItemIcon>
                <ListItemText primary={label} />
              </ListItem>
            </Link>
          );
        })}
      </List>
      <Divider />
      <List>
        {secondaryNavItems.map(({ icon, label, href }) => (
          <Link href={href} key={label} passHref>
            <ListItem button>
              <ListItemIcon style={{ padding: '0 8px' }}>{icon}</ListItemIcon>
              <ListItemText primary={label} />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.logo}>
            <Link href="/" passHref>
              <Image
                src="/logo.png"
                width={150}
                height={50}
                objectFit="contain"
                alt="Edhive Logo"
                className={classes.logoImage}
              />
            </Link>
          </div>
          <Hidden smDown implementation="css">
            {hasPermission(user, actions.VIEW_TRUST_SCHOOLS) && (
              <SchoolPicker />
            )}
            {[...primaryNavItems, ...secondaryNavItems].map(
              ({ href, label, requiredAccess }) => {
                if (requiredAccess && !hasPermission(user, requiredAccess))
                  return null;
                return (
                  <Link href={href} key={label} passHref>
                    <Button className={classes.navBtn}>{label}</Button>
                  </Link>
                );
              }
            )}
          </Hidden>
        </Toolbar>
      </AppBar>

      <nav className={mobileOpen ? classes.drawer : ''}>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Container
          disableGutters={fullWidthPage}
          maxWidth={!fullWidthPage && 'lg'}
          style={{
            ...(!fullWidthPage && { paddingTop: 30, paddingBottom: 30 }),
          }}
        >
          {children}
        </Container>
      </div>
    </div>
  );
}
