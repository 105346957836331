import { useRouter } from 'next/router';
import { makeStyles } from '@material-ui/core/styles';
import { useActiveSchool } from './SchoolProvider';
import { trustDisabledRoutes } from '../constants';

const useStyles = makeStyles((theme) => ({
  input: {
    fontSize: theme.typography.fontSize,
    fontFamily: theme.typography.fontFamily,
    padding: theme.spacing(0.5),
    width: 200,
  },
}));

export default function SchoolPicker() {
  const classes = useStyles();
  const router = useRouter();
  const { schools, activeSchoolId, setActiveSchoolId } = useActiveSchool();

  const handleChange = (event) => setActiveSchoolId(event.target.value);

  if (!schools?.length) return null;

  return (
    <select
      name="activeSchoolId"
      value={activeSchoolId}
      onChange={handleChange}
      className={classes.input}
      disabled={trustDisabledRoutes.includes(router.pathname)}
    >
      {schools?.map((school) => (
        <option key={school.id} value={school.id}>
          {school.name}
        </option>
      ))}
    </select>
  );
}
