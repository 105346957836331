import { UserType as roles } from '@prisma/client';
import { TrustPermission as trustPermissions } from '@prisma/client';

const actions = {
  MANAGE_HEXES: [roles.ADMIN, roles.TRUST_EXECUTIVE],
  MANAGE_AREAS: [roles.ADMIN, roles.TRUST_EXECUTIVE],
  MANAGE_SUBJECTS: [roles.ADMIN, roles.TRUST_EXECUTIVE],
  MANAGE_USERS: [roles.ADMIN, roles.TRUST_EXECUTIVE],
  MANAGE_CAPTURES: [roles.ADMIN, roles.TRUST_EXECUTIVE],
  MANAGE_SCHOOLS: [roles.SUPERADMIN],
  MANAGE_TRUSTEES: [roles.TRUST_ADMIN, roles.TRUST_EXECUTIVE],
  VIEW_NOTIFICATIONS: [roles.TEACHER, roles.ADMIN, roles.TRUST_EXECUTIVE],
  VIEW_TRUST_SCHOOLS: [
    roles.TRUST_USER,
    roles.TRUST_ADMIN,
    roles.TRUST_EXECUTIVE,
  ],
  VIEW_NOTIFICATIONS: [roles.TEACHER, roles.ADMIN, roles.TRUST_EXECUTIVE],
};

const hasPermission = (user, action) => {
  const userGroups = user?.signInUserSession.idToken.payload['cognito:groups'];
  if (!userGroups) return false;
  if (userGroups.includes(roles.SUPERADMIN)) return true;
  return userGroups.some((role) => action.includes(role));
};

const hasRole = (user, permittedRoles) => {
  const userGroups = user?.signInUserSession.idToken.payload['cognito:groups'];
  if (!userGroups) return false;
  if (userGroups.includes(roles.SUPERADMIN)) return true;
  return userGroups.some((role) => permittedRoles.includes(role));
};

const hasTrustPermission = (user, requestedPermission) =>
  Boolean(user?.trustPermissions.includes(requestedPermission));

export {
  roles,
  actions,
  hasRole,
  hasPermission,
  trustPermissions,
  hasTrustPermission,
};
