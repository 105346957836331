import Head from 'next/head';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import AuthProvider from '../components/AuthProvider';
import ConfirmProvider from '../components/ConfirmProvider';
import SchoolProvider from '../components/SchoolProvider';
import Layout from '../components/Layout';
import theme from '../styles/theme';
import '../styles/globals.css';
import '../lib/amplify';

const queryClient = new QueryClient();

export default function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <title>Edhive</title>
        <meta
          name="description"
          content="An exceptionally simple and effective tool to map and develop educational excellence"
        />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <ConfirmProvider>
              <AuthProvider>
                <SchoolProvider>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Layout>
                      <Component {...pageProps} />
                    </Layout>
                  </MuiPickersUtilsProvider>
                </SchoolProvider>
              </AuthProvider>
            </ConfirmProvider>
          </SnackbarProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </>
  );
}
