import Amplify from 'aws-amplify';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: 'eu-west-2',
    userPoolId: 'eu-west-2_b8tpPf9hy',
    userPoolWebClientId: '7dmetgsj8fb206oibsnrt4sn7v',
  },
  ssr: true,
});
