import { useQuery } from 'react-query';
import { useAuth } from '../components/AuthProvider';
import { actions, hasPermission } from '../lib/permissions';

async function fetchTrustSchools() {
  const res = await fetch('/api/trusts/schools');
  return res.json();
}

export default function useTrustSchools() {
  const { user } = useAuth();
  return useQuery('trustSchools', fetchTrustSchools, {
    enabled: !!user && hasPermission(user, actions.VIEW_TRUST_SCHOOLS),
  });
}
